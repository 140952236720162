@media (min-width: 768px) {
  .MemberInfoComponent {
    .card-body {
      position: relative;
    }

    .MembershipTypesInfoComponent {
      position: absolute;
      top: 0;
      right: 0;
      width: 33%;

      padding: 1.25rem;

      .form-group {
        max-width: 100%;
        flex: none;
      }
    }
  }
}