@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.layout-fade {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(211, 211, 211, 0.8);
  z-index: $zindex-modal-backdrop;
}