@import "~startbootstrap-sb-admin/scss/_variables.scss";

#wrapper.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }
  @media (min-width: 768px) {
    footer.sticky-footer {
      width: calc(100% - #{$sidebar-collapsed-width});
    }
  }
}