@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.toasts-container {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0.5;
  padding-right: 10px;
  padding-bottom: 10px;
  z-index: $zindex-popover;

  &:hover{
    opacity: 1;
  }

  .toast {
    opacity: 1;

    .toast-body .close {
      margin-top: -8px;
      margin-right: -4px;
    }
  }
}
